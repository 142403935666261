<template>
  <el-table
    :data="tableData"
    border
    style="width: 100%">
    <el-table-column
      prop="title"
      label="报账项目"
      width="110%">
    </el-table-column>
    <el-table-column
      prop="reconciliationData"
      label="报账数据">
    </el-table-column>
  </el-table>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    const { arrangeNo } = this.$route.query
    this.getData(arrangeNo)
  },
  methods: {
    getData(arrangeNo) {
      axios.get(`${process.env.VUE_APP_BASE_DING}/billArrangeDepartureTime/loadArrangeDepartureTime`, {
        params: {
          arrangeNo
        }
      }).then((resd) => {
        const res = resd.data.data
        this.tableData = [
          {
            title: '班次线路',
            reconciliationData: res.lineName
          }, {
            title: '班次日期',
            reconciliationData: res.arrangeDate
          }, {
            title: '班次时间',
            reconciliationData: res.arrangeRuntime
          }, {
            title: '班次司机',
            reconciliationData: res.driverUserName
          }, {
            title: '对账结果',
            reconciliationData: res.reconciliationResult === 2 ? '符合' : res.reconciliationResult === 3 ? '不符合' : '-'
          }, {
            title: '司机备注',
            reconciliationData: res.driverRemark
          }, {
            title: '班次座位',
            reconciliationData: res.seatOrderNum.driverData
          }, {
            title: '座位',
            reconciliationData: this.formater(res.seatOnlinePay, 'detail')
          }, {
            title: '座位金额',
            reconciliationData: this.formater(res.seatOnlinePay, 'deta')
          }, {
            title: '座位补单',
            reconciliationData: this.formater(res.seatSupplementOnlinePay, 'detail')
          }, {
            title: '座位补单金额',
            reconciliationData: this.formater(res.seatSupplementOnlinePay, 'deta')
          }, {
            title: '带货',
            reconciliationData: this.formater(res.takeGoodsOnlinePay, 'detail')
          }, {
            title: '带货金额',
            reconciliationData: this.formater(res.takeGoodsOnlinePay, 'deta')
          }, {
            title: '带货补单',
            reconciliationData: this.formater(res.takeGoodsSupplementOnlinePay, 'detail')
          }, {
            title: '带货补单金额',
            reconciliationData: this.formater(res.takeGoodsSupplementOnlinePay, 'deta')
          }, {
            title: '网支合计',
            reconciliationData: this.formater(res.sumOnlinePayTotal, 'deta')
          }
        ]
      }).catch(() => {

      })
    },
    formater(data, type) {
      if (type === 'detail') {
        return data.driverArrayDetail.map(el => `[ ${el.title}: ${(el.money / 100).toFixed(2)} ]  `)
      } else if (type === 'deta') {
        return (data.driverData / 100).toFixed(2)
      }
    }
  }
}
</script>

<style>

</style>
